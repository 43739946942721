$(document).foundation();

$(function () {

    $(".action_button").click(function () {
        $(".action_option").toggle();
    });

});

$(function () {

    $(".aviatar").click(function () {
        $(".user_options").toggle();
    });

});


$(".sidebar_menu").click(function () {
    $(".right-sidebar").toggle();
    $(".top_navigation").css("left", "0px");
    $(".app-body").css("left", "0px");
    $(".sidebar_menu").toggle();
    $(".sidebar_menu_show").toggle();
});

$(".sidebar_menu_show").click(function () {
    $(".right-sidebar").toggle();
    $(".top_navigation").css("left", "280px");
    $(".app-body").css("left", "280px");
    $(".sidebar_menu").toggle();
    $(".sidebar_menu_show").toggle();
});

$(".close_client").click(function () {
    $(".client_profile").toggle();
});

var element = document.getElementById('text_box');

element.scrollTop = element.scrollHeight;